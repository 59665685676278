var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CommonAddEditDialog', {
    attrs: {
      "value": _vm.value,
      "is-loading": _vm.state.isLoadingCreatePositionScenario
    },
    on: {
      "add": function add($event) {
        return _vm.listeners.onAddPositionScenario();
      },
      "close": function close($event) {
        return _vm.$emit('close');
      }
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t("planning.workforcePlanning.positionScenario.form.add.title")) + " ")];
      },
      proxy: true
    }, {
      key: "fields",
      fn: function fn() {
        return _vm._l(_vm.constants.FORM_FIELDS, function (entry) {
          return _c('div', {
            key: entry.value
          }, [entry.dataTyp === _vm.constants.DATA_TYPE.TEXT ? _c('v-text-field', {
            class: entry.isRequired && 'required',
            attrs: {
              "label": _vm.$t("planning.workforcePlanning.positionScenario.form.".concat(entry.value)),
              "rules": [].concat(entry.rules)
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e(), entry.fieldType === _vm.constants.FORM_FIELDS_ENUM.TEXTAREA ? _c('v-textarea', {
            class: entry.isRequired && 'required',
            attrs: {
              "label": _vm.$t("planning.workforcePlanning.positionScenario.form.".concat(entry.value)),
              "rules": [].concat(entry.rules),
              "auto-grow": "",
              "rows": "1"
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e(), entry.dataTyp === _vm.constants.DATA_TYPE.DATE ? _c('CommonDateInput', {
            class: entry.isRequired && 'required',
            attrs: {
              "label": _vm.$t("planning.workforcePlanning.positionScenario.form.".concat(entry.value)),
              "rules": [].concat(entry.rules),
              "is-required": entry.isRequired
            },
            model: {
              value: _vm.state.form[entry.value],
              callback: function callback($$v) {
                _vm.$set(_vm.state.form, entry.value, $$v);
              },
              expression: "state.form[entry.value]"
            }
          }) : _vm._e()], 1);
        });
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }