
import { defineComponent, reactive, ref } from '@vue/composition-api'
import { useNotify } from '@/store'

import CommonAddEditDialog from '@/components/common/CommonAddEditDialog.vue'
import CommonDateInput from '@/components/common/CommonDateInput.vue'

import { isEndingDateBeforeStartingDate, isRequired } from '@/utils/validation'
import { handleError } from '@/utils/handleError'
import { dateDotNotationToDashWithForm } from '@/utils/convertDate'

import { DATA_TYPE, FormField, FORM_FIELDS_ENUM } from '@/utils/types/formField'
import { useCreatePositionScenario } from '@/api/positionsScenario'
import { PositionScenarioInput } from '@/api/types/positionScenario'

export default defineComponent({
  name: 'AddEditPositionScenarioDialog',
  components: {
    CommonAddEditDialog,
    CommonDateInput,
  },
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  setup: (_, { root, emit }) => {
    const form = ref<PositionScenarioInput>({} as PositionScenarioInput)

    const { addNotification } = useNotify()

    const FORM_FIELDS: FormField[] = [
      {
        value: 'title',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.TEXT,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('planning.workforcePlanning.positionScenario.form.title') as string),
        ],
      },
      {
        value: 'description',
        fieldType: FORM_FIELDS_ENUM.TEXTAREA,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('planning.workforcePlanning.positionScenario.form.description') as string),
        ],
      },
      {
        value: 'start',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('planning.workforcePlanning.positionScenario.form.start') as string),
        ],
      },
      {
        value: 'end',
        fieldType: FORM_FIELDS_ENUM.TEXT,
        dataTyp: DATA_TYPE.DATE,
        isRequired: true,
        rules: [
          (value: string) =>
            isRequired(value, root.$t('planning.workforcePlanning.positionScenario.form.end') as string),
          (value: string) => (form.value.start ? isEndingDateBeforeStartingDate(form.value.start, value) : true),
        ],
      },
    ]

    const { createPositionScenario, isLoading: isLoadingCreatePositionScenario } = useCreatePositionScenario()

    async function onAddPositionScenario(): Promise<void> {
      let updatedForm = form.value

      updatedForm = dateDotNotationToDashWithForm(FORM_FIELDS, updatedForm)

      try {
        await createPositionScenario(updatedForm as PositionScenarioInput)

        close()

        addNotification({
          text: root.$t('planning.workforcePlanning.positionScenario.create.message') as string,
          type: 'success',
        })
      } catch (error: unknown) {
        handleError(error)
      }
    }

    function close(): void {
      emit('reload-position-scenarios')

      emit('close')
    }

    return reactive({
      constants: {
        FORM_FIELDS_ENUM,

        FORM_FIELDS,
        DATA_TYPE,
      },
      state: {
        form,

        isLoadingCreatePositionScenario,
      },
      listeners: {
        onAddPositionScenario,
      },
      functions: {
        formRules: {
          isRequired,
        },
      },
    })
  },
})
